<template>
  <span class="regular-12 text-black ms-1">
    {{ value.name }}
    <router-link :to="'/sales/user/' + value.id" class="text-start p-2" target="_blank">
      <div class="badge badge-outline">{{ value.orders_count }}</div>
    </router-link>
  </span>
</template>

<script>
export default {
  name: "OrderName",
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
